<script setup>
import { ref } from "vue";
import http from "@/http";

import Card from "@/baseComponents/Card";
import PlusIcon from "vue-material-design-icons/Plus";
import EditIcon from "vue-material-design-icons/Pencil";
import InoculantsIcon from "vue-material-design-icons/Sprout";
import BiodefensiveIcon from "vue-material-design-icons/Leaf";

const loading = ref(false);
const loadingLoadMore = ref(false);
const loadingFiltered = ref(false);
const reports = ref([]);
const allInoculantReportsFetched = ref(false);
const allBiodefensiveReportsFetched = ref(false);
const page = ref(1);
const take = ref(16);
const selectedStatus = ref("");

const getInoculantReports = () => {
  if (allInoculantReportsFetched.value) return;
  return http.get(
    `report/list?orderBy=timestamp&page=${page.value}&take=${take.value}&status=${selectedStatus.value}`
  );
};
const getBiodefensiveReports = () => {
  if (allBiodefensiveReportsFetched.value) return;
  return http.get(
    `report/biodefensive/list?orderBy=timestamp&page=${page.value}&take=${take.value}&status=${selectedStatus.value}`
  );
};

const getReports = () => {
  Promise.all([getInoculantReports(), getBiodefensiveReports()])
    .then((responses) => {
      const inoculantResponse = responses[0];
      const biodefensiveResponse = responses[1];

      if (inoculantResponse) {
        reports.value = [
          ...reports.value,
          ...inoculantResponse.data?.list?.map((report) => {
            if (report.status === "Em análise 5P2R")
              report.status = "Em análise";
            report.type = "Inoculant";
            return report;
          }),
        ];
      }
      if (biodefensiveResponse) {
        reports.value = [
          ...reports.value,
          ...biodefensiveResponse.data?.list?.map((report) => {
            if (report.status === "Em análise 5P2R")
              report.status = "Em análise";
            report.type = "Biodefensive";
            return report;
          }),
        ];
      }

      if (page.value >= inoculantResponse?.data?.pages)
        allInoculantReportsFetched.value = true;
      if (page.value >= biodefensiveResponse?.data?.pages)
        allBiodefensiveReportsFetched.value = true;

      if (
        page.value < inoculantResponse?.data?.pages ||
        page.value < biodefensiveResponse?.data?.pages
      )
        page.value++;
    })
    .finally(() => {
      loading.value = false;
      loadingLoadMore.value = false;
      loadingFiltered.value = false;
    });
};
getReports();

const changeFilterStatus = () => {
  page.value = 1;
  reports.value = [];
  loadingFiltered.value = true;
  allInoculantReportsFetched.value = false;
  allBiodefensiveReportsFetched.value = false;
  getReports();
};
</script>

<template>
  <Card class="animated fadeInLeft mt-3-mobile" style="animation-delay: 150ms">
    <div class="card-header">
      <img class="mobile-hidden" src="@/assets/images/send.svg" alt="Envios" />
      <div class="flex-start">
        <div class="title">Meus envios</div>
        <div class="select-holder">
          <select @change="changeFilterStatus" v-model="selectedStatus">
            <option value="">Todos</option>
            <option value="Em análise 5P2R">Em análise</option>
            <option value="Alteração necessária">Alteração necessária</option>
            <option value="Aprovado">Aprovado</option>
            <option value="Reprovado">Reprovado</option>
          </select>
        </div>
      </div>
      <div class="flex ml-auto-desktop">
        <router-link to="/gerenciar-report/novo" class="btn icon border mt-0">
          <span>Registrar novo relatório</span>
          <PlusIcon fillColor="var(--primary)" />
        </router-link>
      </div>
    </div>

    <div class="divider mt-2"></div>

    <div v-show="loadingFiltered" class="flex-center my-3">
      <div class="loading"></div>
    </div>

    <div v-show="reports.length <= 0 && !loadingFiltered" class="empty mt-2">
      Nenhum registro encontrado.
    </div>

    <div
      v-show="reports.length > 0 && !loadingFiltered"
      class="reports-holder mt-2"
    >
      <router-link
        class="report-card"
        v-for="report in reports"
        :key="report.id"
        title="Acessar"
        :to="`/gerenciar-report/${report.id}?report=${report.type}`"
      >
        <div class="report-title">{{ report.period.name }}</div>
        <div class="sales-category-type">
          <div style="display: contents" v-if="report.type === 'Inoculant'">
            <InoculantsIcon :size="24" fillColor="var(--secondary)" />
            <span>Inoculantes</span>
          </div>
          <div style="display: contents" v-else>
            <BiodefensiveIcon :size="22" fillColor="var(--primary)" />
            <span>Biodefensivos</span>
          </div>
        </div>
        <div class="report-details">
          <div class="volume">
            <span>Volume total</span>
            <span class="color-primary"
              ><b>{{ report.totalVolume }}</b> litros</span
            >
          </div>
          <div class="date">
            <span>Enviado em</span>
            <span class="color-primary">{{
              $filters.dateFilter(report.timestamp)
            }}</span>
          </div>
        </div>
        <div
          class="report-status"
          :class="{
            alteration: report.status == 'Alteração necessária',
            onapproved:
              report.status == 'Aprovado' &&
              report.period.status == 'Em andamento ANPII',
            approved:
              report.status == 'Aprovado' && report.period.status == 'Aprovado',
            disapproved: report.status == 'Reprovado',
          }"
        >
          <div class="circle">
            <span v-show="report.status == 'Alteração necessária'">!</span>
          </div>
          <span
            v-if="
              report.status == 'Aprovado' &&
              report.period.status === 'Em andamento 5P2R'
            "
          >
            Em análise
          </span>
          <span
            v-else-if="
              report.status == 'Aprovado' &&
              report.period.status === 'Em andamento ANPII'
            "
          >
            Em aprovação
          </span>
          <span v-else>{{ report.status }}</span>
          <EditIcon
            class="icon"
            :size="22"
            fillColor="var(--orange)"
            v-show="report.status == 'Alteração necessária'"
          />
        </div>
      </router-link>
    </div>
    <div class="flex-center my-2">
      <div
        @click="getReports"
        v-show="
          (!allBiodefensiveReportsFetched || !allInoculantReportsFetched) &&
          !loadingFiltered
        "
        class="btn border load-more"
      >
        <span v-show="!loadingLoadMore">Carregar mais</span>
        <div v-show="loadingLoadMore" class="loading light-blue"></div>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.card-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
  img {
    position: absolute;
    top: -65px;
    object-fit: cover;
  }
  .flex-start {
    gap: 40px;
  }
  .title {
    margin-left: 140px;
    @media only screen and (max-width: 720px) {
      margin-left: 0px;
      margin-right: auto;
    }
  }
  .select-holder {
    select {
      font-family: fontRegular;
      font-size: 1em;
      color: var(--gray);
      cursor: pointer;
      padding-right: 4px;
    }
  }
}

.reports-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 0.3fr));
  gap: 1.5em;
  align-items: stretch;
  @media only screen and (min-width: 721px) and (max-width: 1159px) {
    grid-template-columns: repeat(auto-fit, minmax(230px, 0.5fr));
  }
  @media only screen and (max-width: 720px) {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
}

.report-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding: 1.3em;
  border-radius: 1.2em;
  border: 1px solid #f1f1f1;
  box-shadow: 0 30px 54px 0 rgba(35, 54, 70, 0.06);
  .sales-category-type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: fit-content;
    margin-top: 6px;
    span {
      font-size: 0.95em;
      color: var(--primary);
    }
  }
  .report-details {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.8em 0px;
    gap: 4px;
    .volume,
    .date {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-family: fontLight;
      }
    }
  }

  .report-status {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    &.alteration {
      .circle {
        background-color: var(--orange);
      }
      span {
        color: var(--orange);
      }
    }
    &.onapproved {
      .circle {
        background-color: #4000e2;
      }
      span {
        color: #4000e2;
      }
    }
    &.approved {
      .circle {
        background-color: var(--secondary);
      }
      span {
        color: var(--secondary);
      }
    }
    &.disapproved {
      .circle {
        background-color: var(--red2);
      }
      span {
        color: var(--red2);
      }
    }
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 1.1em;
      height: 1.1em;
      border-radius: 100%;
      background-color: var(--light-blue);
      span {
        color: #fff;
        z-index: 99;
        font-size: 13px;
        font-family: fontBold;
      }
    }
    span {
      color: var(--light-blue);
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.flex-start {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  @media only screen and (min-width: 721px) and (max-width: 1159px) {
    gap: 20px;
  }
  @media only screen and (max-width: 720px) {
    width: 100%;
    gap: 10px;
  }
}

.empty {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 1.2em;
  height: 100px;
  color: var(--dark4);
}

.load-more {
  border: 1px solid var(--light-blue) !important;
  span {
    color: var(--light-blue) !important;
  }
}
</style>
