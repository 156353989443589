<script setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";

import http from "@/http";
import authentication from "@/util/authentication";
import Header from "../baseComponents/Header";
import Footer from "../baseComponents/Footer";
import Card from "@/baseComponents/Card";
import SalesCategory from "../components/report/SalesCategory";
import Period from "../components/report/Period";
import SaleInoculant from "../components/report/sales/Inoculant";
import SaleBiodefensive from "../components/report/sales/Biodefensive";
import DownloadIcon from "vue-material-design-icons/TrayArrowDown";

const swal = inject("$swal");

const steps = ref(1);
const salesCategoryType = ref("");
const router = useRouter();
const loading = ref(false);
const periods = ref([]);
const report = ref({});
const canEdit = ref(false);
const reportId = router?.currentRoute?.value?.params?.id || "novo";
const queryReportType = router?.currentRoute?.value?.query?.report;
const sendingReport = ref(false);
const defaultSheet = ref("");
const inoculantPermission = authentication?.getUser()?.info?.company?.inoculant;
const biodefensivePermission =
  authentication?.getUser()?.info?.company?.biodefensive;

const getDefaultSheet = () => {
  http
    .get("user/getDefaultSheet")
    .then((response) => {
      defaultSheet.value = response?.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getPeriods = (categoryType) => {
  loading.value = true;
  http
    .get(
      `period/list?orderBy=name&take=-1&reportsNotSent=true&type=${categoryType}`
    )
    .then((response) => {
      periods.value = response?.data?.list;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const setSalesCategory = (categoryType) => {
  salesCategoryType.value = categoryType;
  if (salesCategoryType.value === "Inoculant") {
    getDefaultSheet();
  }
  steps.value++;
  getPeriods(categoryType);
};

if (inoculantPermission && !biodefensivePermission) {
  setSalesCategory("Inoculant");
} else if (!inoculantPermission && biodefensivePermission) {
  setSalesCategory("Biodefensive");
}

const getReport = (endpoint) => {
  loading.value = true;
  http
    .get(`${endpoint}?id=${reportId}`)
    .then((response) => {
      showSale(response?.data, response?.data?.period);
    })
    .catch((err) => {
      console.log(err);
      errorAlert();
    })
    .finally(() => {
      loading.value = false;
    });
};

if (reportId !== "novo") {
  salesCategoryType.value = queryReportType;
  steps.value = 3;
  if (queryReportType === "Inoculant") {
    getReport("report/details");
    getDefaultSheet();
  } else getReport("report/biodefensive/details");
}

const showSale = (reportValue, selectedPeriod) => {
  if (reportId === "novo" || reportValue.status === "Alteração necessária") {
    canEdit.value = true;
  }
  if (reportValue.status === "Em análise 5P2R")
    reportValue.status = "Em análise";
  report.value = reportValue;
  report.value.period = selectedPeriod;
  report.value.userId = authentication?.getUser()?.info?.id;
  steps.value = 3;
};

const sendReport = (report, type) => {
  sendingReport.value = true;
  const requestEndpoint =
    type === "Inoculant" ? "report" : "report/biodefensive";
  const method = reportId === "novo" ? "post" : "put";
  http[method](requestEndpoint, report)
    .then(() => {
      swal.fire({
        toast: true,
        icon: "success",
        title: "Obrigado pelo envio!",
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
      router.push("/home");
    })
    .catch((err) => {
      console.log(err);
      swal.fire({
        position: "center",
        icon: "warning",
        title: "Algo deu errado",
        text: "Por favor, verifique se todos os campos estão preenchidos corretamente.",
        showConfirmButton: true,
      });
    })
    .finally(() => {
      sendingReport.value = false;
    });
};

const goBack = () => {
  if (steps.value === 2 && (!inoculantPermission || !biodefensivePermission)) {
    router.push("/home");
    return;
  }
  steps.value--;
};

const errorAlert = () => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Ops...",
    text: "Algo deu errado, tente novamente em instantes.",
    showConfirmButton: true,
  });
};
</script>

<template>
  <div class="all">
    <Header />

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
    </transition>
    <div v-show="sendingReport" class="full-loading">
      <div class="loading"></div>
      <p>Por favor, aguarde.</p>
    </div>

    <div class="container mt-3 mt-1-mobile mb-3 mb-1-mobile">
      <Card class="animated zoomIn">
        <div class="card-header">
          <img
            class="mobile-hidden"
            src="@/assets/images/report.svg"
            alt="Envios"
          />
          <div class="title">Novo relatório de vendas</div>
          <a
            v-show="steps === 2 && !loading"
            :href="
              salesCategoryType === 'Inoculant'
                ? defaultSheet
                : '/files/Biodefensivos-Planilha_Modelo.xlsx'
            "
            target="_blank"
            :download="
              salesCategoryType === 'Inoculant'
                ? 'Inoculantes-Planilha_Modelo.xlsx'
                : 'Biodefensivos-Planilha_Modelo.xlsx'
            "
            class="btn icon border tablet5 mobile12 mt-0 ml-auto"
          >
            <span>
              Baixar planilha modelo:
              {{
                salesCategoryType === "Inoculant"
                  ? "Inoculantes"
                  : "Biodefensivos"
              }}
            </span>
            <DownloadIcon fillColor="var(--primary)" />
          </a>
        </div>

        <div class="divider mt-2"></div>

        <transition enter-active-class="animated zoomIn">
          <div v-show="loading" class="flex-center my-3">
            <div class="loading"></div>
          </div>
        </transition>

        <SalesCategory
          class="animated zoomIn"
          v-if="steps === 1 && !loading"
          @setSalesCategory="setSalesCategory"
        />
        <Period
          class="animated zoomIn"
          v-if="steps === 2 && !loading"
          :salesCategoryType="salesCategoryType"
          :periods="periods"
          @reportSentSuccessfully="showSale"
          @goBack="goBack"
        />
        <SaleInoculant
          class="animated zoomIn"
          v-if="steps === 3 && !loading && salesCategoryType === 'Inoculant'"
          :report="report"
          :canEdit="canEdit"
          @sendReport="sendReport"
        />
        <SaleBiodefensive
          class="animated zoomIn"
          v-if="steps === 3 && !loading && salesCategoryType === 'Biodefensive'"
          :report="report"
          :canEdit="canEdit"
          @sendReport="sendReport"
        />
      </Card>
    </div>

    <Footer class="footer animated fadeInUp" style="animation-delay: 700ms" />
  </div>
</template>

<style lang="scss" scoped>
.all {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.footer {
  padding: 40px 0 0 0;
}

.card-header {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
    gap: 20px;
  }
  img {
    position: absolute;
    top: -65px;
    object-fit: cover;
  }
  .title {
    margin-left: 120px;
    @media only screen and (max-width: 720px) {
      margin-left: 0px;
      margin-right: auto;
    }
  }
}
</style>
