<script setup>
import { defineEmits } from "vue";

import ArrowIcon from "vue-material-design-icons/ChevronRight";
import InoculantsIcon from "vue-material-design-icons/Sprout";
import BiodefensiveIcon from "vue-material-design-icons/Leaf";

const emit = defineEmits(["setSalesCategory"]);

const setSalesCategory = (categoryType) => {
  emit("setSalesCategory", categoryType);
};
</script>

<template>
  <div class="category-holder mt-2">
    <span class="category-label">Selecione a categoria das vendas *</span>
    <div class="category-itens mb-2 mt-2">
      <div class="item">
        <InoculantsIcon :size="36" fillColor="var(--secondary)" />
        <span class="item-type color-secondary mt-1">Inoculantes</span>
        <div class="flex mt-2">
          <div
            @click="setSalesCategory('Inoculant')"
            class="btn btn-inoculants border icon"
          >
            <span>Avançar</span>
            <ArrowIcon fillColor="var(--secondary)" />
          </div>
        </div>
      </div>
      <div class="item">
        <BiodefensiveIcon :size="36" fillColor="var(--primary)" />
        <span class="item-type color-primary mt-1">Biodefensivos</span>
        <div class="flex mt-2">
          <div
            @click="setSalesCategory('Biodefensive')"
            class="btn border icon"
          >
            <span>Avançar</span>
            <ArrowIcon fillColor="var(--primary)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.category-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .category-label {
    font-size: 1.1em;
    font-family: fontRegular;
    color: var(--primary);
  }
  .category-itens {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 70px;
    width: 100%;
    margin: 0 auto;
    @media only screen and (max-width: 720px) {
      gap: 30px;
      flex-direction: column;
      align-items: center;
    }
    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: fit-content;
      background-color: #fff;
      padding: 2em 3em;
      border-radius: 1.2em;
      box-shadow: var(--box-shadow-default);
      @media only screen and (max-width: 720px) {
        padding: 2em 2em !important;
        width: 100%;
      }
      .item-type {
        font-size: 1.3em;
      }
      .btn-inoculants {
        border: 1px solid var(--secondary);
        span {
          color: var(--secondary);
        }
      }
    }
  }
}

.send-excel {
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
