<script setup>
import { ref, inject, defineEmits, defineProps } from "vue";

import http from "@/http";
import ArrowIcon from "vue-material-design-icons/ChevronDown";
import UploadIcon from "vue-material-design-icons/CloudUploadOutline";
import BackIcon from "vue-material-design-icons/ChevronLeft";
import InoculantsIcon from "vue-material-design-icons/Sprout";
import BiodefensiveIcon from "vue-material-design-icons/Leaf";

const props = defineProps({
  salesCategoryType: {
    type: String,
    default: "",
  },
  periods: {
    type: Array,
    default: () => {
      return [];
    },
  },
});

const emit = defineEmits(["reportSentSuccessfully", "goBack"]);
const loadingImportSales = ref(false);
const selectedFile = ref(null);
const selectedPeriod = ref(null);
const file = ref(null);

const setFile = (event) => {
  if (!selectedPeriod.value?.id) {
    swal.fire({
      icon: "warning",
      title: "Período das vendas",
      text: "por favor, escolha um período!",
    });
    file.value = null;
    return;
  }

  file.value = event.target.files[0];

  importSales(file.value);
};

const importSales = (objFile) => {
  if (!objFile) return;

  loadingImportSales.value = true;

  const formData = new FormData();
  formData.append("file", objFile);

  const requestEndpoint =
    props.salesCategoryType === "Inoculant"
      ? "report/importSales"
      : "report/biodefensive/importSales";

  http
    .post(`${requestEndpoint}?periodId=${selectedPeriod.value.id}`, formData)
    .then((response) => {
      sucessAlert();
      emit("reportSentSuccessfully", response.data, selectedPeriod);
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 400) {
        errorAlert(err.response.data);
        file.value = null;
      } else {
        errorAlert(
          "Certifique-se que o arquivo enviado está no padrão fornecido pela plataforma."
        );
      }
    })
    .finally(() => {
      loadingImportSales.value = false;
    });
};

const goBack = () => {
  emit("goBack");
};

const swal = inject("$swal");
const sucessAlert = () => {
  swal.fire({
    toast: true,
    icon: "success",
    title: "Planilha carregada com sucesso!",
    position: "top-right",
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
  });
};
const errorAlert = (msg) => {
  swal.fire({
    position: "center",
    icon: "warning",
    title: "Algo deu errado",
    text: msg,
    showConfirmButton: true,
  });
};
</script>

<template>
  <div class="period-holder mt-2">
    <div class="sales-category">
      <div @click="goBack" class="back">
        <BackIcon :size="24" fillColor="#626262" />
        <span>Voltar</span>
      </div>
      <div class="type">
        <InoculantsIcon
          v-if="props.salesCategoryType === 'Inoculant'"
          :size="36"
          fillColor="var(--secondary)"
        />
        <BiodefensiveIcon v-else :size="36" fillColor="var(--primary)" />
        <span
          :class="{
            'color-secondary': props.salesCategoryType === 'Inoculant',
          }"
        >
          {{
            salesCategoryType === "Inoculant" ? "Inoculantes" : "Biodefensivos"
          }}
        </span>
      </div>
    </div>

    <div class="input-item mobile12">
      <span>Selecione o período das vendas *</span>
      <div class="input-holder">
        <select v-model="selectedPeriod">
          <option
            v-for="period in props.periods"
            :key="period.id"
            :value="period"
          >
            {{ period.name }}
          </option>
        </select>
        <div class="unit-holder">
          <ArrowIcon fillColor="var(--dark4)" />
        </div>
      </div>
    </div>
    <div class="flex-center mb-2 mt-3">
      <div class="btn icon send-excel">
        <span v-show="!loadingImportSales"
          >Fazer envio da planilha preenchida</span
        >
        <UploadIcon :size="30" fillColor="#fff" v-show="!loadingImportSales" />
        <input
          type="file"
          ref="selectedFile"
          v-show="!loadingImportSales"
          accept=".xlsx, .xls, .csv"
          @input="setFile"
        />
        <div v-show="loadingImportSales" class="loading white"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.period-holder {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .sales-category {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;

    .back {
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      width: fit-content;
      @media only screen and (max-width: 720px) {
        gap: 2px;
      }
      span {
        display: flex;
        color: #626262;
        font-size: 1em;
      }
    }
    .type {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      @media only screen and (max-width: 720px) {
        gap: 10px;
      }
      span {
        font-size: 1.3em;
        color: var(--primary);
      }
    }
  }
  .input-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 50%;
    margin-top: 60px;
    span {
      font-size: 1.1em;
      font-family: fontRegular;
      color: var(--primary);
    }
    select {
      font-size: 1em;
      color: var(--primary);
      padding: 0 50px 0 15px;
    }
  }
}

.send-excel {
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
